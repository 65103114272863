<template>
  <b-card>
    <baseform
      :fields="fields"
      :posturl="dataurl"
      :title="title"
    />
  </b-card>
</template>

<script>
import baseform from '@/views/base/BaseForm.vue'
import {BCard} from 'bootstrap-vue'

export default {
  components: {
    baseform,
    BCard,
  },
  data() {
    return {
      dataurl:"/tire-size",
      baseroute:"operasional-tire-size",
      title:"Ukuran Ban Kendaraan",
      fields : [
        { key: 'name', label: 'Name', type: 'input', rules:'required' },
      ]
    }
  },
}
</script>